import React from "react"
import SubAccounts from "../../../components/body/pages/en-ng/business/sub-account"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

const SubAccountsPage = () => (
  <Layout>
    <SEO
      canonical={"https://kuda.com/en-ng/business/sub-accounts"}
      title="Sub-Accounts | Automate and Streamline Your Payroll with Bento and Kuda | Kuda Business"
      description="Discover how Kuda's sub-accounts make managing your business finances a breeze. Streamline expenses, enhance financial control, and grow your business with ease"
    />

    <SubAccounts />
  </Layout>
)

export default SubAccountsPage
